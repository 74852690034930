import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticStyle: {
      "background": "#fcfcfc"
    }
  }, [_c('div', {
    staticClass: "se-title"
  }, [_c('p', [_vm._v("账号安全")]), _c('div', {
    staticClass: "label marginleft20"
  }, [_vm._v(" 你当前的帐号："), _c('label', [_vm._v(_vm._s(_vm.account))])])]), _vm.curIndex == 1 ? _c('div', [_c('div', {
    staticClass: "se-wrapper v2-g-flex-row v2-g-flex-space-between v2-g-align-center"
  }, [_c('div', {
    staticClass: "left-wrapper v2-g-flex-row v2-g-align-center"
  }, [_c('img', {
    staticStyle: {
      "height": "34px"
    },
    attrs: {
      "src": require("@/assets-v2/images/wdzh_bangdingshouji.png")
    }
  }), _c('div', {
    staticClass: "content-phone"
  }, [_c('span', {
    staticClass: "left-phone"
  }, [_vm._v("绑定手机 " + _vm._s(_vm.startPhone))]), _vm.endPhone.length ? _c('span', {
    staticClass: "gd"
  }, [_vm._v(" 更多 "), _c('div', {
    staticClass: "show-card"
  }, _vm._l(_vm.endPhone, function (item, index) {
    return _c('span', {
      key: index
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  }), 0)]) : _vm._e(), _c('label', [_vm._v("绑定手机可以用于短信验证码登录帐号,重置密码等")])])]), _c('button', {
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        _vm.curIndex = 3;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.isBindPhone ? "更换绑定" : "立即绑定") + " ")])]), _c('div', {
    staticClass: "se-wrapper v2-g-flex-row v2-g-flex-space-between v2-g-align-center"
  }, [_vm._m(0), _c('button', {
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        _vm.curIndex = 2;
      }
    }
  }, [_vm._v("修改密码")])]), _c('div', {
    staticClass: "se-wrapper v2-g-flex-row v2-g-flex-space-between v2-g-align-center"
  }, [_vm._m(1), _c('button', {
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        _vm.curIndex = 4;
      }
    }
  }, [_vm._v("重置密码")])])]) : _vm._e(), _vm.curIndex == 2 ? _c('div', [_c('ModifyPwd', {
    on: {
      "back": _vm.back
    }
  })], 1) : _vm._e(), _vm.curIndex == 3 ? _c('div', [_c('ChangePhone', {
    attrs: {
      "isExistPhone": _vm.isBindPhone,
      "phone": _vm.Phonedata
    },
    on: {
      "back": _vm.back
    }
  })], 1) : _vm._e(), _vm.curIndex == 4 ? _c('div', [_c('ResetPwd', {
    attrs: {
      "phone": _vm.Phonedata.toString(),
      "isExistPhone": _vm.isBindPhone
    },
    on: {
      "back": _vm.back,
      "bindPhone": function bindPhone($event) {
        _vm.curIndex = 3;
      }
    }
  })], 1) : _vm._e()]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "left-wrapper v2-g-flex-row v2-g-align-center"
  }, [_c('img', {
    staticStyle: {
      "height": "34px"
    },
    attrs: {
      "src": require("@/assets-v2/images/wdzh_xiugaimima.png")
    }
  }), _c('div', {
    staticClass: "content"
  }, [_c('span', [_vm._v("修改密码 ")]), _c('label', [_vm._v("通过原密码修改新的密码")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "left-wrapper v2-g-flex-row v2-g-align-center"
  }, [_c('img', {
    staticStyle: {
      "height": "34px"
    },
    attrs: {
      "src": require("@/assets-v2/images/wdzh_chongzhimima.png")
    }
  }), _c('div', {
    staticClass: "content"
  }, [_c('span', [_vm._v("重置密码 ")]), _c('label', [_vm._v("忘记登录密码,通过绑定手机号直接重置密码")])])]);
}];
export { render, staticRenderFns };