var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "reset-wrapper"
  }, [!_vm.isExistPhone ? _c('div', [_vm._m(0), _c('div', {
    staticClass: "form-field btn v2-g-flex-row"
  }, [_c('button', {
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.back
    }
  }, [_vm._v("返回")]), _c('button', {
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.bindPhone
    }
  }, [_vm._v("去绑定")])])]) : _c('div', [_c('div', {
    staticClass: "form-field"
  }, [_c('p', [_vm._v("通过已绑定的手机重置密码：" + _vm._s(_vm.phone))])]), _vm._m(1), _c('div', {
    staticClass: "ch-row yzm v2-g-flex-row v2-g-align-center"
  }, [_c('label', [_vm._v("验证码")]), _c('div', {
    staticClass: "input-code"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.code,
      expression: "code"
    }],
    attrs: {
      "type": "text",
      "placeholder": "验证码"
    },
    domProps: {
      "value": _vm.code
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.code = $event.target.value;
      }
    }
  }), !_vm.showTime ? _c('span', {
    staticClass: "get-code",
    on: {
      "click": _vm.getCode
    }
  }, [_vm._v("获取验证码")]) : _c('span', {
    staticClass: "get-code"
  }, [_vm._v(_vm._s(_vm.time) + "秒后重发")])])]), _c('div', {
    staticClass: "form-field btn v2-g-flex-row"
  }, [_c('button', {
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.back
    }
  }, [_vm._v("返回")]), _c('button', {
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.modify
    }
  }, [_vm._v("修改")])])])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "form-field"
  }, [_c('p', [_vm._v("您尚未绑定手机号，请先绑定手机号或直接联系客服进行密码重置！")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "form-field tip"
  }, [_c('p', [_vm._v("安全提示：绑定手机号后支持手机验证码登录")])]);
}];
export { render, staticRenderFns };