var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "modify-pwd"
  }, [_c('div', {
    staticClass: "form-field v2-g-flex-row"
  }, [_c('label', [_vm._v("原密码")]), _c('div', {
    staticClass: "field"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.oldPwd,
      expression: "oldPwd"
    }],
    ref: "oldPwd",
    attrs: {
      "type": "password",
      "autocomplete": "new-password",
      "placeholder": "请输入原密码"
    },
    domProps: {
      "value": _vm.oldPwd
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.oldPwd = $event.target.value;
      }
    }
  }), _vm.index == 1 ? _c('span', {
    staticClass: "error"
  }, [_vm._v(_vm._s(_vm.error))]) : _vm._e()])]), _c('div', {
    staticClass: "form-field v2-g-flex-row"
  }, [_c('label', [_vm._v("新密码")]), _c('div', {
    staticClass: "field"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.newPwd,
      expression: "newPwd"
    }],
    ref: "newPwd",
    attrs: {
      "type": "password",
      "autocomplete": "new-password",
      "placeholder": "请输入新密码(至少6位)"
    },
    domProps: {
      "value": _vm.newPwd
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.newPwd = $event.target.value;
      }
    }
  }), _vm.index == 2 ? _c('span', {
    staticClass: "error"
  }, [_vm._v(_vm._s(_vm.error))]) : _vm._e()])]), _c('div', {
    staticClass: "form-field v2-g-flex-row"
  }, [_c('label', [_vm._v("确认密码")]), _c('div', {
    staticClass: "field"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.confirmPwd,
      expression: "confirmPwd"
    }],
    ref: "confirmPwd",
    attrs: {
      "type": "password",
      "autocomplete": "new-password",
      "placeholder": "请再次输入新密码"
    },
    domProps: {
      "value": _vm.confirmPwd
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.confirmPwd = $event.target.value;
      }
    }
  }), _vm.index == 3 ? _c('span', {
    staticClass: "error"
  }, [_vm._v(_vm._s(_vm.error))]) : _vm._e()])]), _c('div', {
    staticClass: "form-field btn v2-g-flex-row"
  }, [_c('button', {
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.back
    }
  }, [_vm._v("返回")]), _c('button', {
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.modify
    }
  }, [_vm._v("修改")])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };