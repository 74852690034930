var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "ch-phone-wrapper"
  }, [_c('div', {
    staticClass: "step-wrapper v2-g-flex-row v2-g-align-center"
  }, [_c('div', {
    staticClass: "step",
    class: _vm.nextStep >= 1 ? 'cur' : ''
  }, [_c('span', [_vm._v("1")]), _c('span', {
    staticClass: "label"
  }, [_vm._v("验证身份")])]), _c('div', {
    staticClass: "line",
    class: _vm.nextStep >= 2 ? 'cur' : ''
  }), _c('div', {
    staticClass: "step",
    class: _vm.nextStep >= 2 ? 'cur' : ''
  }, [_c('span', [_vm._v("2")]), _c('span', {
    staticClass: "label"
  }, [_vm._v("更换手机号")])]), _c('div', {
    staticClass: "line",
    class: _vm.nextStep >= 3 ? 'cur' : ''
  }), _c('div', {
    staticClass: "step",
    class: _vm.nextStep == 3 ? 'cur' : ''
  }, [_c('span', [_vm._v("3")]), _c('span', {
    staticClass: "label"
  }, [_vm._v("更换完成")])])]), _vm._m(0), _vm.nextStep == 1 ? _c('div', {
    staticClass: "ch-row"
  }, [_vm.isExistPhone ? _c('p') : _vm._e(), _c('div', {
    staticClass: "ch-row yzm v2-g-flex-row v2-g-align-center"
  }, [_c('label', {
    staticClass: "ch-row-l"
  }, [_vm._v("已绑定的手机")]), _c('div', {
    staticClass: "input-code-1"
  }, [_c('b-select', {
    attrs: {
      "size": "is-medium",
      "model": _vm.phone,
      "placeholder": _vm.phone[0]
    },
    on: {
      "input": _vm.changeInvitationRole
    }
  }, _vm._l(_vm.phone, function (option, index) {
    return _c('option', {
      key: index,
      domProps: {
        "value": option
      }
    }, [_vm._v(" " + _vm._s(option) + " ")]);
  }), 0)], 1)]), _c('p'), !_vm.isExistPhone ? _c('p', [_vm._v(" 当前您未绑定手机号，请输入当前帐号登录密码验证通过后进行绑定 ")]) : _vm._e()]) : _vm._e(), _vm.nextStep == 1 ? _c('div', [_vm.isExistPhone ? _c('div', {
    staticClass: "ch-row yzm v2-g-flex-row v2-g-align-center"
  }, [_c('label', [_vm._v("验证码")]), _c('div', {
    staticClass: "input-code"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.code,
      expression: "code"
    }],
    attrs: {
      "type": "text",
      "placeholder": "验证码"
    },
    domProps: {
      "value": _vm.code
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.code = $event.target.value;
      }
    }
  }), !_vm.showTime ? _c('span', {
    staticClass: "get-code",
    on: {
      "click": _vm.getCode
    }
  }, [_vm._v("获取验证码")]) : _c('span', {
    staticClass: "get-code"
  }, [_vm._v(_vm._s(_vm.time) + "秒后重发")])])]) : _vm._e(), !_vm.isExistPhone ? _c('div', {
    staticClass: "ch-row yzm v2-g-flex-row v2-g-align-center"
  }, [_c('label', [_vm._v("登录密码")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.pwd,
      expression: "pwd"
    }],
    staticClass: "yzm-field",
    attrs: {
      "type": "text",
      "placeholder": "登录密码"
    },
    domProps: {
      "value": _vm.pwd
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.pwd = $event.target.value;
      }
    }
  })]) : _vm._e()]) : _vm._e(), _vm.nextStep == 2 ? _c('div', {
    staticClass: "step2"
  }, [_c('div', {
    staticClass: "ch-row phone v2-g-flex-row v2-g-align-center"
  }, [_c('label', [_vm._v("手机号")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.newPhone,
      expression: "newPhone"
    }],
    attrs: {
      "type": "text",
      "placeholder": "输入手机号"
    },
    domProps: {
      "value": _vm.newPhone
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.newPhone = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "ch-row yzm v2-g-flex-row v2-g-align-center"
  }, [_c('label', [_vm._v("验证码")]), _c('div', {
    staticClass: "input-code"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.seconde_code,
      expression: "seconde_code"
    }],
    attrs: {
      "type": "text",
      "placeholder": "验证码"
    },
    domProps: {
      "value": _vm.seconde_code
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.seconde_code = $event.target.value;
      }
    }
  }), !_vm.showTime_second ? _c('span', {
    staticClass: "get-code",
    on: {
      "click": _vm.getSecondCode
    }
  }, [_vm._v("获取验证码")]) : _c('span', {
    staticClass: "get-code"
  }, [_vm._v(_vm._s(_vm.time_second) + "秒后重发")])])])]) : _vm._e(), _vm.nextStep == 3 ? _c('div', [_c('p', [_vm._v("绑定完成！您可以通过当前手机号" + _vm._s(_vm.newPhone) + "和验证码方式进行登录")])]) : _vm._e(), _c('div', {
    staticClass: "ch-row btn v2-g-flex-row"
  }, [_c('button', {
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.back
    }
  }, [_vm._v("返回")]), _vm.nextStep != 3 ? _c('button', {
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.next
    }
  }, [_vm._v("下一步")]) : _vm._e()])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "ch-row tip"
  }, [_c('p', [_vm._v("安全提示：绑定手机号后支持手机验证码登录")]), _c('p', [_vm._v(" 若该手机号已无法使用请联系客服")])]);
}];
export { render, staticRenderFns };